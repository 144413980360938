<template>
  <!-- <van-pull-refresh :head-height="50" v-model="refreshing" @refresh="onRefresh"> -->
  <div id="trip">
    <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="10" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />行程详情
          </van-col>
          <van-col
            span="14"
            class="header-title"
            style="text-align: right"
            @click="onDeleteClick"
          >
            删除
          </van-col>
        </van-row>
      </div>
    </van-sticky>
    <div class="trip-wrapper">
      <div class="trip-body">
        <div class="trip-body-item border-dashed-bottom">
          <van-row class="trip-item-body">
            <van-col span="6">
              <van-image class="item-avatar" round :src="plans.image_url" />
            </van-col>
            <van-col span="18">
              <div class="item-name">{{ plans.plan_name }}</div>
              <div class="item-sub-text">
                {{ plans.travel_company ? plans.travel_company : "暂无" }}
              </div>
              <!-- <div class="item-sub-text">
                {{ plans.description ? plans.description : "暂无" }}
              </div> -->
            </van-col>
          </van-row>
          <van-row class="trip-info">
            <van-col span="8">
              <div class="trip-start-address">
                {{ plans.start_addr ? plans.start_addr : "暂无" }}
              </div>
            </van-col>
            <van-col span="8">
              <div class="to">
                <svg-icon :data_iconName="'to'" :className="'to-icon'" />
              </div>
            </van-col>
            <van-col span="8">
              <div class="trip-end-address">
                {{ plans.end_addr ? plans.end_addr : "暂无" }}
              </div>
            </van-col>
          </van-row>
          <!-- <div class="circle-left"></div>
        <div class="circle-right"></div> -->
          <div class="trip-step">
            <van-steps
              direction="vertical"
              active-color="#969799"
              active-icon="clock"
              :active="-1"
            >
              <van-step v-for="(item, index) in plans.nodes" :key="index">
                <h3>{{ item.node_name }}</h3>
                <p>{{ item.node_description }}</p>
              </van-step>
            </van-steps>
          </div>
        </div>
      </div>
      <van-row class="tuan-container">
        <van-col span="24" class="tuan-title">出团记录</van-col>
        <van-col span="24">
          <van-empty description="暂无数据" v-if="plans.travel_lists.length == 0"/>
        </van-col>
        <van-col
          span="24"
          class="message-tuan"
          v-for="(tuan, index) in plans.travel_lists"
          :key="index"
          @click="onTuanClick(tuan)"
        >
          <van-row class="tuan-info">
            <van-col span="10">
              <div class="tuan-start-time text-left">
                {{ tuan.start_date }}
              </div>
              <div class="tuan-start-address text-left">
                {{ plans.start_addr ? plans.start_addr : "暂无" }}
              </div>
            </van-col>
            <van-col span="4">
              <div class="need-time">{{ tuan.days ? tuan.days : "0" }}天</div>
              <!-- <div class="tuan-items"  @click.stop="onShowClick(tuan)">行程景点</div> -->
            </van-col>
            <van-col span="10">
              <div class="tuan-end-time text-right">{{ tuan.end_date }}</div>
              <div class="tuan-end-address text-right">
                {{ plans.end_addr ? plans.end_addr : "暂无" }}
              </div>
            </van-col>
          </van-row>
          <div class="start-date">团名：{{ tuan.list_name }}</div>
          <div class="start-date" style="margin-top: 0">
            出发日期：{{ tuan.start_date }}
          </div>
          <div class="tuan-ren-number">
            团队人数：{{ tuan.tourists_count ? tuan.tourists_count : 0 }}人
          </div>
          <div :class="[stateClass[tuan.status]]">{{ tuan.status_text }}</div>
        </van-col>
      </van-row>
    </div>
  </div>
  <!-- </van-pull-refresh> -->
</template>

<script>
import { Toast, Dialog } from "vant";
export default {
  name: "trip",
  components: {},
  data() {
    return {
      plans: {},
      active: 1,
      show: false,
      item: {},
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        plan_name: "",
      },
      stateClass: [
        "status-btn-plan",
        "status-btn-ing",
        "status-btn-completed",
        "status-btn-cancel",
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onItemClick() {
      this.$router.push("/trip/item");
    },
    onEditClick() {
      this.$router.push({
        path: "/trip/edit",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    onTuanClick(item) {
      this.$router.push({
        path: "/tuan/item",
        query: {
          id: item.id,
        },
      });
    },
    onDeleteClick() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定删除该行程吗?",
      })
        .then(() => {
          // on confirm
          _self.delete_travel_plan();
        })
        .catch(() => {
          // on cancel
        });
    },
    async delete_travel_plan() {
      const id = this.$route.query.id;
      const res = await this.$apis.delete_travel_plan(id);
      if (res.status == 200) {
        Toast("删除成功");
        this.$router.go(-1);
      }
    },
    onShowClick(item) {
      this.item = item;
      this.show = true;
    },
    async get_travel_plan(id) {
      const res = await this.$apis.get_travel_plan(id);
      this.plans = res.data;
      this.loading = false;
      this.refreshing = false;
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      const id = this.$route.query.id;
      if (!id) {
        Toast("参数错误");
        this.$router.go(-1);

        return false;
      }
      this.get_travel_plan(id);
    },
  },
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#trip {
  height: 100vh;
  overflow-y: scroll;
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.trip-wrapper {
  height: 85vh;
  overflow-y: scroll;
  position: fixed;
  top: 10vh;
  left: 0;
  width: 100%;
  padding-bottom: 2rem;
}
.trip-body {
  /* height: 85vh; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem;
  overflow-y: scroll;
  /* padding-bottom: 2rem; */
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  margin-top: 0.4rem;
  padding-bottom: 0.2rem;
}
.trip-body-item {
  background: #ffff;
  /* border-radius: 20px; */
  width: 100%;
  /* height: 3.2rem; */
  position: relative;
  /* padding: 0.2rem; */
  margin-bottom: 10px;
  z-index: 10;
  position: relative;
}
.trip-body-item .item-avatar {
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.trip-body-item .item-avatar img {
  height: 100%;
  height: 100%;
}
.trip-body-item .item-name {
  margin-top: .2rem;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
.trip-body-item .item-sub-text {
  color: #666;
  font-size: 12px;
  /* position: absolute;
  top: 0.8rem;
  left: 1.3rem; */
}
.trip-body-item .item-agency-text {
  font-size: 14px;
  color: #666;
  position: absolute;
  top: 1.05rem;
  left: 1.3rem;
}
.lang {
  background: rgba(148, 174, 64, 0.18);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #94ae40;
  font-size: 12px;
  margin-left: 10px;
}
.lang2 {
  background: rgba(75, 183, 78, 0.13);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #4bb74e;
  font-size: 12px;
  margin-left: 10px;
}
.border-dashed-bottom {
  border-bottom: 1px dashed #f3f3f3;
}
.trip-info {
  /* margin-top: 1.6rem; */
  height: 1.6rem;
}
.trip-start-time,
.trip-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.trip-start-address,
.trip-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
  height: 1.6rem;
  line-height: 1.6rem;
}
.trip-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
  border-top: 1px solid #cccccc;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.circle-left {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #436ef0;
  z-index: 10;
  top: 50%;
  left: -0.2rem;
  border-radius: 50%;
}
.circle-right {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background-image: linear-gradient(
    179deg,
    #6c94f6 0%,
    #436ef0 16%,
    #3a5be9 100%
  );
  z-index: 10;
  top: 50%;
  right: -0.2rem;
  border-radius: 50%;
}
.to-icon {
  display: inline-block;
  width: 100%;
}
.to {
  height: 1.6rem;
  line-height: 1.6rem;
}
.trip-item-body {
  height: 1.6rem;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.tuan-title {
  color: #333;
  font-size: 0.3rem;
  font-weight: bold;
  margin-top: 0.3rem;
}
.tuan-container {
  background: #fff;
  width: 90%;
  margin: 0 auto;
  padding: 0.2rem;
  /* margin-top: 30vh; */
}

.message-tuan {
  background: #f3f3f3;
  padding: 0.4rem;
  border-radius: 20px;
  position: relative;
  margin-top: 10px;
}
.tuan-info {
  /* margin-top: 1.6rem; */
}
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.status-btn-cancel {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: #6f6f6f23;
  color: #606060;
  padding: 2px 0.2rem;
}
.status-btn-completed {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: #13C2C212;
  color: #13C2C2;
  padding: 2px 0.2rem;
}
.status-btn-plan {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  color: #4168EE;
  background: #4168EE12;
  padding: 2px 0.2rem;
}
.status-btn-ing {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  color: #fff;
  background: #4168EE;
  padding: 2px 0.2rem;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.wrapper {
  width: 90vw;
  margin: 0 auto;
  background: #fff;
  border-radius: 20px;
  border-radius: 20px;
  height: 60vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0.3rem;
  overflow-y: scroll;
}
.wrpper-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.wrpper-text {
  color: #666;
  font-size: 12px;
}
</style>
